import React from "react";
import "./Header.css";
//import { motion } from 'framer-motion'
const Header = () => {
  return (
    <header className="header" initial={{opacity: 0, y: -180}} animate={{opacity: 1, y: 0}} transition={{ease: "easeInOut", duration:1,delay:0.4}}>
      <nav className="nav container">
        <a href="index.html" className="nav__logo">
          César
        </a>

        <div className="nav__menu">
          <ul className="nav__list">
            <a
                href="https://www.linkedin.com/in/cesaralvarezllaneza/"
                className="home__social-icon"
                target="_blank"
                rel="noreferrer"
            >
                <i className="uil uil-linkedin"></i>
            </a>

            <a
                href="https://github.com/cesaralvrz"
                className="home__social-icon"
                target="_blank"
                rel="noreferrer"
            >
                <i className="uil uil-github"></i>
            </a>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
