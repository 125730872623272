import React from "react";
import Number from "./Number";
import Data from "./Data";
import ScrollDown from "./ScrollDown";
import BackgroundAnimation from "../background/BackgroundAnimation.js"


import "./Home.css";

function Home() {
  return (
    <section className="home__section" id="home">
      <div className="home__container container grid">
        <div className="home__content grid">
            <div className="home__number">
                <Number />
            </div>
            <div className="home__data">
                <Data/>
            </div>
            <div className="home__img">
                <BackgroundAnimation />
            </div>
        </div>
        <ScrollDown/>
      </div>
    </section>
  );
}

export default Home;
