import React from "react";
import "./Skills.css"
import Frotend from "./Frotend";


function Skills() {
    return (
      <section className="skills section" id="skills">
        <h2 className="section__title">Skills</h2>
        <span className="section__subtitle">What I do:</span>

        <div className="skills__container">
          <Frotend />
        </div>
      </section>
    );
}

export default Skills;
