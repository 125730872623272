import React from "react";

function Frotend() {
  return (
    <div className="skills__content">
      <div className="skills__box">
        <div className="skills__group">
        <div className="skills__data">
            <i className="bx bxl-vuejs"></i>
            <div>
              <h3 className="skills__name">Vue.js</h3>
              <span className="skills__level">(2, 3, Vuex, Pinia, Router)</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bxl-react"></i>
            <div>
              <h3 className="skills__name">React.js</h3>
              <span className="skills__level">(Redux, Hooks)</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bxl-angular"></i>
            <div>
              <h3 className="skills__name-no-label">Angular</h3>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bxl-typescript"></i>
            <div>
              <h3 className="skills__name-no-label">Typescript</h3>
            </div>
          </div>
        </div>
        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bxl-css3"></i>
            <div>
              <h3 className="skills__name">CSS3</h3>
              <span className="skills__level">(Sass, Less)</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bxl-bootstrap"></i>
            <div>
              <h3 className="skills__name">CSS Frameworks</h3>
              <span className="skills__level">(Tailwind, Bootstrap)</span>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bxl-html5"></i>
            <div>
              <h3 className="skills__name-no-label">HTML5</h3>
            </div>
          </div>
          <div className="skills__data">
            <i className="bx bxl-git"></i>
            <div>
              <h3 className="skills__name-no-label">GIT & Github</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frotend;
